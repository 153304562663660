<template>
    <div>
      <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      <v-row>
        <v-col cols="2">
          <v-text-field v-model="senderEmail" outlined placeholder="Enter sender email" hide-details/>
          <div style="display: flex;"><small class="text--disabled" style="text-align: center;">This appears in the 'from' field in the email, @knowthegame.ie is added automatically</small></div>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="toEmail" outlined placeholder="Enter to email" hide-details/>
          <div style="display: flex;"><small class="text--disabled" style="text-align: center;">This appears in the 'to' field in the email, it should be something like 'players' or 'active' etc. @knowthegame.ie is added automatically</small></div>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="subject" outlined placeholder="Enter subject" hide-details/>
        </v-col>
        <v-col cols="4">
          <v-select :items="getEmailRecipients" v-model="recipients"
                placeholder="recipients" outlined hide-details hide-no-data></v-select>
                <div style="display: flex;"><small class="text--disabled" style="text-align: center;">{{ recipiantHelperText }}</small></div>
        </v-col>
        <v-col cols="2">
          <v-select :items="sports" v-model="sport"
                placeholder="Sport" outlined hide-details hide-no-data></v-select>
        </v-col>
        <v-col cols="12">
          <vue-editor v-model="emailContent" :options="editorOptions"></vue-editor>
        </v-col>
        <v-col cols="10"/>
        <v-col cols="2">
          <v-btn @click="sendEmailPressed" primary color="primary" :disabled="!(sendEmail && subject && emailContent && recipients && sport && toEmail)">Send Email</v-btn>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import { VueEditor  } from 'vue2-quill-editor';
  import { mapActions } from 'vuex'
import { mapGetters } from 'vuex/dist/vuex.common.js';
  
  export default {
    components: { VueEditor  },
    mounted(){
      this.fetchEmailRecipients();
    },
    data() {
      return {
        emailContent: '',
        senderEmail: 'no-reply',
        toEmail: '',
        subject: '',
        editorOptions: {
          theme: 'snow',
        },
        loading: false,
        sport: '',
        sports: ['HURLING', 'FOOTBALL', 'RUGBY', 'SOCCER'],
        recipients: ''
      };
    },
    methods: {
      ...mapActions(['sendEmail', 'fetchEmailRecipients']),
      async sendEmailPressed() {
        this.loading = true;
        const payload = {
          sender: this.senderEmail+'@knowthegame.ie',
          to: this.toEmail+'@knowthegame.ie',
          content: this.emailContent,
          subject: this.subject,
          sport: this.sport,
          recipients: this.recipients
        };
        let resp = await this.sendEmail(payload);
        this.loading = false;
        console.log(resp.data);
      },
    },
    computed: {
      ...mapGetters(['getEmailRecipients']),
      recipiantHelperText(){
        if(this.recipients == 'ALL_ACTIVE'){
          return 'All players who have paid to enter the league for the sport'
        } else if(this.recipients == 'ALL'){
          return 'All players, regardless of payment or sport - USE WITH CAUTION'
        } else if(this.recipients == 'MISSED_LAST_GAMEWEEK'){
          return 'All players who did not enter guesses LAST week, and therefore scored 0'
        } else if(this.recipients == 'MISSED_THIS_GAMEWEEK'){
          return 'All players who did not enter guesses THIS week, and need a reminder'
        } else {
          return '';
        }
      }
    }
  };
  </script>